import React from "react"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "./screens/minutes-matter/hero"
import ThanksComponent from "./screens/minutes-matter/thank-you"
import BodyLanding from "../components/body-landing"

const ThankYou = ({ location }) => {
	const firstName = (location.state) ? location.state.first_name : ""
	return (
		<main>
			<Header />
			<Hero />
			<ThanksComponent first_name={firstName} />
			<BodyLanding landingPage="minutes-matter" />
		</main>
	)
}

export default ThankYou

export const Head = () => (
	<Seo
	  title="Minutes Matter"
	  description="Get PCR STI results in 28 minutes to improve patient length of stay, relieve resource strain and patient satisfaction scores."
	/>
  )
